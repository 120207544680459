<template>
  <!--选择提货地址-->
  <div>
    <el-dialog title="选择提货地址" :visible.sync="product" class="product" width="900px" style="min-width:1100px" custom-class="productWidth" @close="closeTable">
      <el-form :model="searchData">
        <el-row style="width:100%;margin:0 auto">
          <el-col :span="8">
            <el-form-item label="联系人" label-width="60px">
              <el-input v-model="searchData.contacts" class="se36" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话" label-width="80px">
              <el-input v-model="searchData.phone" class="se36" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button round type="primary" style="margin-left:10px;min-width:110px;background:#507EFF" icon="el-icon-search" class="bhe36" @click="addressByPage">查 询</el-button>
            <el-button round type="primary" style="margin-left:10px;min-width:110px;" class="bhe36" @click="goTo">添加提货地址</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        ref="table"
        class="freightRate-table center"
        :data="tableData"
        style="width: 100%;margin-bottom:20px"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <el-table-column
          prop="contacts"
          label="联系人"
        />
        <el-table-column
          prop="phone"
          label="联系电话"
        />
        <el-table-column
          prop="countryName"
          label="国家"
        />
        <el-table-column
          prop="stateName"
          label="省/州"
        />
        <el-table-column
          prop="cityName"
          label="城市"
        />
        <el-table-column
          prop="address"
          label="提货地址"
        />
      </el-table>
      <el-pagination
        class="fenye"
        background
        layout="prev, pager, next"
        :current-page="searchData.pageNum"
        :page-size="searchData.pageSize"
        :total="total"
        @current-change="handleCurrentChange"
      />
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeTable">取 消</el-button>
        <el-button type="primary" @click="SubmitTable">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="添加提货地址"
      :visible.sync="address"
      class="address"
      custom-class="w840"
      @close="address=false"
    >
      <el-form :model="addData">
        <el-row style="margin:0 auto">
          <el-col :span="12">
            <el-form-item label="联系人" label-width="60px">
              <el-input v-model="addData.contacts" class="se36" />
            </el-form-item>
            <el-form-item label="国家" label-width="60px">
              <el-input v-model="addData.countryName" class="se36" />
            </el-form-item>
            <el-form-item label="城市" label-width="60px">
              <el-input v-model="addData.cityName" class="se36" />
            </el-form-item>
            <el-form-item label="邮编" label-width="60px">
              <el-input v-model="addData.zipCode" class="se36" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" label-width="80px">
              <el-input v-model="addData.phone" class="se36" />
            </el-form-item>
            <el-form-item label="省/州" label-width="80px">
              <el-input v-model="addData.stateName" class="se36" />
            </el-form-item>
            <el-form-item label="提货地址" label-width="80px">
              <el-input v-model="addData.address" type="textarea" :rows="3" class="se36" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <p style="text-align:center;">
        <el-button class="ebuton" type="primary" round @click="saveDeliveryAddress">确定</el-button>
        <el-button class="nbuton" type="primary" round @click="closeModel">关闭</el-button>
      </p>
    </el-dialog>
  </div>
</template>

<script>
import { addressByPage } from '@/api/freight.js';
import { saveDeliveryAddress } from '@/api/goods.js';
export default {
  name: 'SelectProduct',
  props: ['visible'],
  data: function() {
    return {
      product: this.visible, // 选择商品
      searchData: {
        pageNum: 1,
        pageSize: 10,
        types: 1
      },
      tableData: [], // 商品table
      total: 0, // 商品table，
      address: false,
      addData: {
        contacts: '',
        countryName: '',
        cityName: '',
        zipCode: '',
        phone: '',
        stateName: '',
        address: '',
        types: 1
      }
    };
  },
  watch: {
    visible(newVal) {
      this.product = newVal;
    }
  },
  mounted() {
    this.addressByPage();
  },
  methods: {
    addressByPage() { // 分页商品列表
      addressByPage(this.searchData).then(result => {
        if (result.status == 200) {
          const data = result.data.data;
          this.tableData = data.list;
          this.total = data.total;
          this.searchData.pageSize = data.pageSize;
          this.searchData.pageNum = data.pageNum;
        }
      });
    },
    closeTable() {
      this.product = false;
      this.searchData = { pageNum: 1, pageSize: 10 };
      this.$emit('close');
    },
    SubmitTable() { // 确定选择
      const sel = this.$refs['table'].selection;
      this.closeTable();
      this.$emit('sublime', sel);
    },
    goTo() {
      this.address = true;
    },
    handleCurrentChange(val) { // 选择商品切换页数
      this.searchData.pageNum = val;
      this.addressByPage();
    },
    saveDeliveryAddress() { // 添加地址
      saveDeliveryAddress(this.addData).then(result => {
        if (result.status == 200) {
          this.$message({
            message: '添加成功',
            type: 'success'
          });
          this.closeModel();
        }
      }).catch(error => {
        console.log(error);
      });
    },
    closeModel() { // 关闭窗口清除数据
      this.addData = {};
      this.address = false;
      this.addressByPage();
    }
  }
};
</script>

<style scoped>
</style>
