<template>
  <!--选择商品-->
  <el-dialog
    title="选择商品"
    :visible.sync="product"
    class="product"
    style="min-width: 1100px"
    custom-class="productWidth"
    @close="closeTable"
  >
    <el-form inline label-position="right" label-width="130px">
      <el-form-item label="S K U：">
        <el-input v-model="searchData.sku" size="mini" clearable />
      </el-form-item>
      <el-form-item label="商品名称(中文)：">
        <el-input
          v-model="searchData.nameCn"
          size="mini"
          clearable
        />
      </el-form-item>
      <el-form-item label="海关编码：">
        <el-input
          v-model="searchData.hsCode"
          size="mini"
          clearable
        />
      </el-form-item>
      <el-form-item label="品牌：">
        <el-input
          v-model="searchData.brand"
          size="mini"
          clearable
        />
      </el-form-item>
      <el-form-item label="材质：">
        <el-input
          v-model="searchData.materialQuality"
          size="mini"
          clearable
        />
      </el-form-item>
      <el-form-item label=" ">
        <el-button
          round
          type="primary"
          style="
            min-width: 110px;
            background: #507eff;
            margin-bottom: 10px
          "
          icon="el-icon-search"
          @click="goodsByPage"
        >查 询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="table"
      class="freightRate-table center"
      :data="tableData"
      style="width: 100%; margin-bottom: 20px"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="sku" label="SKU" />
      <el-table-column prop="nameCn" label="商品名称(中文)" />
      <el-table-column prop="typesName" label="商品类型" />
      <el-table-column prop="brand" label="品牌" />
      <el-table-column prop="specification" label="型号" />
      <el-table-column prop="materialQuality" label="材质" />
      <el-table-column prop="purpose" label="用途" />
      <el-table-column prop="declaredValue" label="申报价值(USD)" />
      <el-table-column prop="hsCode" label="海关编码" />
      <el-table-column prop="serviceUnitPrice" label="附加费" />
    </el-table>
    <el-pagination
      class="fenye"
      background
      layout="prev, pager, next"
      :current-page="searchData.pageNum"
      :page-size="searchData.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
    />
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeTable">取 消</el-button>
      <el-button type="primary" @click="SubmitTable">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { goodsByPage } from '@/api/goods.js';
export default {
  name: 'SelectProduct',
  props: ['visible', 'filterGoodsId'],
  data: function() {
    return {
      product: this.visible, // 选择商品
      searchData: {
        pageNum: 1,
        pageSize: 10,
        status: 1,
        offerInfoId: this.$route.query.id,
        filterGoodIds: [],
        hsCode: '',
        brand: ''
      },
      tableData: [], // 商品table
      total: 0 // 商品table
    };
  },
  watch: {
    visible(newVal) {
      this.product = newVal;
    },
    filter_goods_id: {
      handler(val) {
        this.searchData.filterGoodIds = val;
        this.goodsByPage();
      },
      deep: true
    }
  },
  mounted() {
    this.goodsByPage();
  },
  methods: {
    goodsByPage() {
      // 分页商品列表
      goodsByPage(this.searchData).then((result) => {
        if (result.status == 200) {
          const data = result.data.data;
          this.tableData = data.list;
          this.total = data.total;
          this.searchData.pageSize = data.pageSize;
          this.searchData.pageNum = data.pageNum;
        }
      });
    },
    closeTable() {
      this.product = false;
      this.searchData = {
        pageNum: 1,
        pageSize: 10,
        status: 1,
        offerInfoId: this.$route.query.id
      };
      this.$emit('close');
    },
    SubmitTable() {
      // 确定选择
      const sel = this.$refs['table'].selection;
      this.closeTable();
      this.$emit('sublime', sel);
    },
    handleCurrentChange(val) {
      // 选择商品切换页数
      this.searchData.pageNum = val;
      this.goodsByPage();
    }
  }
};
</script>

<style scoped>
.product /deep/ .el-dialog {
  width: 60%;
}
.product /deep/.se36 input {
  width: 200px;
  height: 26px;
  float: left;
}
.input {
  float: left;
}
.lable {
  float: left;
  font-size: 15px;
  margin-top: 20px;
}
.lable[data-v-b83b025a] {
  margin-top: 3px;
}
::v-deep .el-form-item{
  margin-bottom: 0;
}
</style>
