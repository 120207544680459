<template>
  <div>
    <!-- <el-table
      class="freightRate-table center virtul_box1"
      :data="resdata"
      style="width: 100%"
      @scroll.native="handleScroll"
    >
      <el-table-column prop="cartonNo" label="箱号"></el-table-column>
      <el-table-column prop="amazonReferenceId" label="亚马逊引用ID">
        <template slot-scope="scope">
          <el-input v-model="scope.row.amazonReferenceId"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="extensionNumber" label="扩展单号"></el-table-column>
      <el-table-column prop="fabNo" label="FBA箱号" width="260px">
        <template slot-scope="scope">
          <el-input
            placeholder="请输入FBA箱号"
            v-model="scope.row.fabNo"
            class="se36"
          />
        </template>
      </el-table-column>
      <el-table-column prop="asnWidth" label="重量(kg)" width="100px">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.asnWeight"
            class="se36"
            @blur="changeComputeData"
          />
        </template>
      </el-table-column>
      <el-table-column prop="cutOffTime" label="长*宽*高" width="240px">
        <template slot-scope="scope">
          <el-col :span="6">
            <el-input
              placeholder="长"
              v-model="scope.row.asnLength"
              class="se36"
              @blur="changeComputeData"
            ></el-input> </el-col
          ><el-col :span="2"
            ><span
              style="
                line-height: 36px;
                width: 100%;
                text-align: center;
                display: block;
              "
              >*</span
            ></el-col
          >
          <el-col :span="6">
            <el-input
              placeholder="宽"
              v-model="scope.row.asnWidth"
              class="se36"
              @blur="changeComputeData"
            ></el-input> </el-col
          ><el-col :span="2"
            ><span
              style="
                line-height: 36px;
                width: 100%;
                text-align: center;
                display: block;
              "
              >*</span
            ></el-col
          >
          <el-col :span="6">
            <el-input
              placeholder="高"
              v-model="scope.row.asnHeight"
              class="se36"
              @blur="changeComputeData"
            ></el-input>
          </el-col>
        </template>
      </el-table-column>
      <el-table-column  label="体积" width="100px" align="center">
        <template slot-scope="scope">
          {{
            scope.row.asnVolume
          }}
        </template>
      </el-table-column>
      <el-table-column  label="操作" width="100px" align="center">
        <template slot-scope="scope">
          <el-button type="danger" size="mini" @click="delxiangziHandleClick(scope.$index, boxTableData)">删除</el-button>
        </template>
      </el-table-column>
    </el-table> -->
    <div class="virtul_box1">
      <div class="table_head">
        <div class="item" style="flex: 0 1 140px">箱号</div>
        <div class="item">亚马逊引用ID</div>
        <div class="item">扩展单号</div>
        <div class="item" style="flex: 0 1 178px">FBA箱号</div>
        <div class="item" style="flex: 0 1 90px">重量(kg)</div>
        <div class="item" style="flex: 0 1 294px">长*宽*高</div>
        <div class="item" style="flex: 0 1 90px">体积</div>
        <div class="item" style="flex: 0 1 100px">操作</div>
      </div>
      <div
        class="box"
        style="height: 750px; overflow-x: hidden; overflow-y: auto"
      >
        <div class="box" :style="{ minHeight: tableData.length * 70 + 'px' }">
          <div ref="transformEL" class="drag">
            <div
              v-for="(item, index) in resdata"
              :key="index"
              class="table_body"
            >
              <div class="item" style="flex: 0 1 140px">
                <span>{{ item.cartonNo }}</span>
              </div>
              <div class="item">
                <el-input v-model="item.amazonReferenceId" />
              </div>
              <div class="item">
                <span>{{ item.extensionNumber }}</span>
              </div>
              <div class="item" style="flex: 0 1 178px">
                <el-input
                  v-model="item.fabNo"
                  placeholder="请输入FBA箱号"
                  class="se36"
                />
              </div>
              <div class="item" style="flex: 0 1 90px">
                <el-input v-model="item.asnWeight" class="se36" @blur="changeComputeData" />
              </div>
              <div class="item" style="flex: 0 1 294px">
                <el-col :span="6">
                  <el-input
                    v-model="item.asnLength"
                    placeholder="长"
                    class="se36"
                    @blur="changeComputeData"
                  /> </el-col><el-col
                  :span="2"
                ><span
                  style="
                      line-height: 36px;
                      width: 100%;
                      text-align: center;
                      display: block;
                    "
                >*</span></el-col>
                <el-col :span="6">
                  <el-input
                    v-model="item.asnWidth"
                    placeholder="宽"
                    class="se36"
                    @blur="changeComputeData"
                  /> </el-col><el-col
                  :span="2"
                ><span
                  style="
                      line-height: 36px;
                      width: 100%;
                      text-align: center;
                      display: block;
                    "
                >*</span></el-col>
                <el-col :span="6">
                  <el-input
                    v-model="item.asnHeight"
                    placeholder="高"
                    class="se36"
                    @blur="changeComputeData"
                  />
                </el-col>
              </div>
              <div class="item" style="flex: 0 1 90px">
                <span>{{ item.asnVolume }}</span>
              </div>
              <div class="item" style="flex: 0 1 100px">
                <el-button
                  type="danger"
                  size="mini"
                  @click="delxiangziHandleClick(startIndex + index)"
                >删除</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { calcOrderCaseList } from '@/api/freight.js';
export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    rowHeight: {
      type: Number,
      default: 70
    },
    showNum: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      startIndex: 0,
      endIndex: 15,
      totalHeight: 0,
      testData: []
    };
  },
  computed: {
    resdata() {
      let res = this.tableData;
      res = res.slice(this.startIndex, this.endIndex);
      return res;
    }
  },
  created() {
    this.initData();
  },
  mounted() {
    this.listener();
  },
  methods: {
    initData() {
      const arr = [];
      for (let i = 0; i < 10000; i++) {
        arr.push({
          amazonReferenceId: i + 1,
          cartonNo: i + 1,
          extensionNumber: i + 1,
          fabNo: i + 1,
          asnWidth: i + 1,
          asnLength: i + 1,
          asnHeight: i + 1,
          asnWeight: i + 1,
          asnVolume: i + 1
        });
      }
      this.testData = arr;
    },
    listener() {
      this.$nextTick(() => {
        const el = document.querySelector('.virtul_box1 > .box');
        const el2 = this.$refs.transformEL;
        el.addEventListener('scroll', (e) => {
          const scrollTop = e.target.scrollTop;
          this.startIndex =
            Math.floor(scrollTop / this.rowHeight) - 5 < 0
              ? 0
              : Math.floor(scrollTop / this.rowHeight) - 5;
          this.endIndex = this.startIndex + this.showNum + 5 * 2;
          el2.style.webkitTransform = `translate3d(0, ${
            this.startIndex * 70
          }px, 0)`;
        });
      });
    },

    changeComputeData() {
      calcOrderCaseList({
        offerInfoId: +this.$route.query.oId,
        orderCaseVOList: this.resdata
      }).then((res) => {
        if (+res.data.code === 0) {
          // this.goods_weight = res.data.data;
          // this.boxTableData = res.data.data.orderCaseVOList;
          this.$emit('computedRow', res);
        }
      });
    },
    delxiangziHandleClick(index) {
      this.$emit('delData', index);
    }
  }
};
</script>

<style scoped>
.virtul_box1 {
  height: 800px;
}
.table_head {
  min-height: 50px;
  background-image: linear-gradient(#b1c3f4, #9ab1f1);
  color: #fff;
  border-radius: 10px 10px 0 0;
  display: flex;
}
.table_head .item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  padding: 0 10px;
  box-sizing: border-box;
}
.table_body {
  display: flex;
}
.table_body .item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ebeef5;
  height: 70px;
  overflow: hidden;
  padding: 0 10px;
  box-sizing: border-box;
}
</style>
