<template>
  <div class="GoOrder">
    <div class="c-box">
      <el-row class="head">
        <el-col
          style="width: 34.5%;margin-right: 20px;"
          :span="15"
        ><div class="head-right">
          <p class="title">操作说明<el-button v-if="forData.specialVersion" type="primary" class="special-message" @click="specialMessage">特别说明</el-button>
            <el-popover
              v-if="$route.query.edit"
              class="special-message2"
              placement="bottom"
              title="补充资料说明"
              width="400"
              trigger="click"
              :content="forData.fillMaterialDescription"
            >
              <el-button slot="reference" type="primary" style="width: 120px">补充资料说明</el-button>
            </el-popover>
          </p>
          <div style="height:360px; overflow:hidden;">
            <p v-for="(obj,index) in forData.remarksList" :key="index">{{ obj }}</p>
          </div>
        </div></el-col><el-col :span="1" style=""><i class="hr" /></el-col>
        <el-col
          :span="8"
        ><div class="head-left">
          <el-row>
            <el-col :span="24">
              <p>产品名称：{{ forData.names }}</p>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="13">
              <p>运输方式：{{ forData.tname }}</p>
            </el-col>
            <el-col :span="11">
              <p>
                货物类型：<span v-show="forData.gidtype == 1">普货</span><span v-show="forData.gidtype == 2">特货</span>
              </p>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="13">
              <p>起 运 港：{{ forData.startShipmentName }}</p>
            </el-col>
            <el-col :span="11">
              <p>
                目 的 港：<span>{{ forData.destinationPortName }}</span>
              </p>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="13">
              <p>
                航 程：<span
                  v-show="forData.voyageDay"
                >{{ forData.voyageDay }}天</span>
              </p>
            </el-col>
            <el-col :span="11">
              <p>预计到达：{{ reTime(forData.estimatedTime) }}</p>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="13">
              <p style="min-height:30px">
                开船日期：<span class="bianda">{{ reTime(forData.sailTime) }}</span>
              </p>
              <p v-show="forData.sailTimeRemark" style="color:red">{{ forData.sailTimeRemark }}</p>
            </el-col>
            <el-col :span="11">
              <p>
                截单日期：<span class="bianda">{{ reTime(forData.cutOffTime) }}</span>
              </p>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="13">
              <p>
                截仓日期：<span class="bianda">{{ reTime(forData.jcTime) }}</span>
              </p>
            </el-col>
            <el-col :span="11">
              <p>
                截亏仓日期：<span class="bianda">{{ reTime(forData.jkcTime) }}</span>
              </p>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="13">
              <p>
                <!-- 结算方式：<span class="bianda" v-if="forData.clearingWay === 1">票结</span><span class="bianda" v-if="forData.clearingWay === 2 && forData.showClearingWay === 1">票结</span><span class="bianda" v-if="forData.clearingWay === 2 && forData.showClearingWay === 2">月结</span> -->
                结算方式：<span class="bianda">{{ NowClearingWay }}</span>
              </p>
            </el-col>
          </el-row>
        </div></el-col>
      </el-row>
      <p class="click">
        <span
          @click="operatingInfo = true"
        ><i class="el-icon-arrow-down" />更多</span>
      </p>
    </div>
    <div class="border" />
    <div class="c-box mt20">
      <el-row>
        <el-col :span="8">
          <div style="height: 400px; background: #ccc">
            <img :src="forData.picUrl" style="width: 100%;    height: 100%;">
          </div>
          <div style="margin-top: 18px; margin-bottom: 20px">
            <div
              style="
                width: 80px;
                height: 80px;
                background: #ccc;
                display: inline-block;
                margin-right: 18px;
              "
            />
            <div
              style="
                width: 80px;
                height: 80px;
                background: #ccc;
                display: inline-block;
                margin-right: 18px;
              "
            />
            <div
              style="
                width: 80px;
                height: 80px;
                background: #ccc;
                display: inline-block;
                margin-right: 18px;
              "
            />
          </div>
        </el-col>
        <el-col :span="16">
          <el-form
            ref="formSelect"
            :model="formData"
            :rules="rulesformData"
            label-width="100px"
            class="form"
          >
            <el-form-item
              label="定舱区间"
              label-width="100px"
              prop="reserveSize"
            >
              <span
                v-for="(item, i) in forData.oceanFeeList"
                :key="i"
                onselectstart="return false"
                :class="{
                  button: true,
                  select: formData.reserveSize == item.specificationCode,
                }"
                :title="item ? item.specificationName : ''"
                @click="
                  reserveSize(item.specificationCode, 'reserveSize', item)
                "
              >{{ item ? item.specificationName : "" }}</span>
            </el-form-item>
            <el-form-item label="金额">
              <p class="money" style="margin: 0; min-height: 1px">
                <span
                  class="money"
                ><i>{{ guijin }}</i></span>
              </p>
            </el-form-item>
            <el-form-item
              label="集货仓库"
              label-width="100px"
              prop="storeGoodsWarehouseCode"
            >
              <span
                v-for="(item, i) in forData.shippingAreaVOList"
                :key="i"
                onselectstart="return false"
                class="button"
                :class="{
                  button: true,
                  select:
                    formData.storeGoodsWarehouseCode == item.warehouseCode,
                }"
                :title="item ? item.warehouseName : ''"
                @click="
                  reserveSize(
                    item.warehouseCode,
                    'storeGoodsWarehouseCode',
                    item
                  )
                "
              >{{ item ? item.warehouseName : "" }}</span>
              <!-- <p class="remind">
                不同的集货仓库将会产生内陆费用，请以实际账单为准
              </p> -->
            </el-form-item>
            <!-- <el-form-item label="金额">
              <p class="money" style="margin: 0; min-height: 1px">
                <span class="money"
                  ><i v-show="formData.isPick == 1">{{ kujin }}</i></span
                >
              </p>
            </el-form-item> -->
            <el-form-item label="目的地仓库" prop="destinationWarehouseCode">
              <el-select
                v-model="formData.destinationWarehouseCode"
                filterable
                placeholder="请选择"
                class="se36"
                @change="showWarehouseAddress"
              >
                <el-option
                  v-for="(item, i) in forData.fabWarehouseVOList"
                  :key="i"
                  :label="item.warehouseName"
                  :value="item.warehouseCode"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="目的仓库地址">
              <span>{{ formData.destinationWarehouseAddress }}</span>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <div class="border" />
    <div class="c-box mt20">
      <el-card>
        <el-form
          ref="addData"
          :rules="resAddData"
          :model="addData"
          label-width="100px"
          class="form"
          style="display: flex;"
        >
          <el-form-item
            label="亚马逊引用ID"
            label-width="110px"
          >
            <el-input
              v-model="addData.amazonReferenceId"
              style="width:150px"
              class="se36"
            />
          </el-form-item>
          <el-form-item
            label="扩展单号"
            prop="extensionNumber"
            label-width="110px"
          >
            <el-input
              v-model="addData.extensionNumber"
              style="width:150px"
              class="se36"
            />
          </el-form-item>
          <el-form-item
            label="起始单号"
            prop="beginNumber"
            label-width="110px"
          >
            <el-input
              v-model="addData.beginNumberPrefix"
              style="width:80px;margin-right:20px"
              class="se36"
            />
            <el-input
              v-model="addData.beginNumber"
              style="width:150px"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              class="se36"
            />
          </el-form-item>
        </el-form>
        <el-row>
          <el-form
            ref="addData"
            :rules="resAddData"
            :model="addData"
            label-width="100px"
            class="form"
          >
            <el-col :span="5">
              <el-form-item label="总箱数" label-width="65px" prop="cartons">
                <el-input
                  v-model="addData.cartons"
                  placeholder="箱数"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  class="se36"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="每箱重量(KG)"
                prop="weight"
                label-width="110px"
              >
                <el-input
                  v-model="addData.weight"
                  placeholder="重量"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  class="se36"
                />
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item label="长宽高(CM)" required>
                <el-col :span="6">
                  <el-form-item prop="length">
                    <el-input
                      v-model="addData.length"
                      placeholder="长"
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                      class="ckg se36"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="width">
                    <el-input
                      v-model="addData.width"
                      placeholder="宽"
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                      class="ckg se36"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="height">
                    <el-input
                      v-model="addData.height"
                      placeholder="高"
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                      class="ckg se36"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-button
                    type="primary"
                    class="bhe36"
                    @click="batchAdd"
                  >批量添加</el-button>
                </el-col>
              </el-form-item>
            </el-col>
            <input
              ref="upload"
              type="file"
              accept=".xls,.xlsx"
              style="display: none"
              @change="readExcel"
            >
            <input
              ref="upload2"
              type="file"
              accept=".xls,.xlsx"
              style="display: none"
              @change="readOwnExcel"
            >
            <el-button style="margin-right: 20px" type="primary" size="mini" @click="importFrontEndExcel">导入数据（旧系统模板）</el-button>
            <el-button type="primary" size="mini" @click="$refs.upload2.click()">导入数据</el-button>
            <el-button type="primary" size="mini" @click="downloadTemplate">下载模板</el-button>
            <a ref="downloadEL" href="/static/Template.xlsx" target="_blank" download="Template.xlsx" style="display:none" />
          </el-form>
        </el-row>
        <el-row v-if="goods_weight_show" style="margin-bottom:10px">
          <el-col :span="5">
            <span>实际体积:{{ goods_weight.totalAsnVolume }}</span>
          </el-col>
          <el-col :span="5">
            <span>实际重:{{ goods_weight.totalAsnWeight }}</span>
          </el-col>
          <el-col :span="5">
            <span>总箱数:{{ goods_weight.totalCase }}</span>
          </el-col>
          <el-col :span="5">
            <span>收费重:{{ goods_weight.totalChargeWeight }}（单位：
              <span v-if="(forData.billingWeightUnit === 1)">柜</span>
              <span v-if="(forData.billingWeightUnit === 2)">KG</span>
              <span v-if="(forData.billingWeightUnit === 3)">CBM</span>
              <span v-if="(forData.billingWeightUnit === 4)">车</span>）
            </span>
          </el-col>
          <el-col :span="4">
            <span>材积重:{{ goods_weight.totalVolumeWeight }}</span>
          </el-col>
        </el-row>
        <el-collapse accordion>
          <el-collapse-item>
            <template slot="title">
              请填写FBA箱号信息，如未填写则以系统生成箱号为准
            </template>
            <div
              v-loading="virtulLoading"
              style="
                border: 1px solid #eee;
                border-radius: 5px;
                overflow: hidden;
                margin-bottom: 20px;
              "
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
            >
              <!-- <el-table
                class="freightRate-table center"
                :data="boxTableData"
                style="width: 100%"
                height="800"
              >
                <el-table-column prop="cartonNo" label="箱号"> </el-table-column>
                <el-table-column prop="amazonReferenceId" label="亚马逊引用ID">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.amazonReferenceId"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="extensionNumber" label="扩展单号"> </el-table-column>
                <el-table-column prop="fabNo" label="FBA箱号" width="260px">
                  <template slot-scope="scope">
                    <el-input
                      placeholder="请输入FBA箱号"
                      v-model="scope.row.fabNo"
                      class="se36"
                    />
                  </template>
                </el-table-column>
                <el-table-column prop="asnWidth" label="重量(kg)" width="100px">
                  <template slot-scope="scope">
                    <el-input
                      placeholder=""
                      v-model="scope.row.asnWeight"
                      class="se36"
                      @blur="changeComputeData"
                    />
                  </template>
                </el-table-column>
                <el-table-column prop="cutOffTime" label="长*宽*高" width="240px">
                  <template slot-scope="scope">
                    <el-col :span="6">
                      <el-input
                        placeholder="长"
                        v-model="scope.row.asnLength"
                        class="se36"
                        @blur="changeComputeData"
                      ></el-input> </el-col
                    ><el-col :span="2"
                      ><span
                        style="
                          line-height: 36px;
                          width: 100%;
                          text-align: center;
                          display: block;
                        "
                        >*</span
                      ></el-col
                    >
                    <el-col :span="6">
                      <el-input
                        placeholder="宽"
                        v-model="scope.row.asnWidth"
                        class="se36"
                        @blur="changeComputeData"
                      ></el-input> </el-col
                    ><el-col :span="2"
                      ><span
                        style="
                          line-height: 36px;
                          width: 100%;
                          text-align: center;
                          display: block;
                        "
                        >*</span
                      ></el-col
                    >
                    <el-col :span="6">
                      <el-input
                        placeholder="高"
                        v-model="scope.row.asnHeight"
                        class="se36"
                        @blur="changeComputeData"
                      ></el-input>
                    </el-col>
                  </template>
                </el-table-column>
                <el-table-column  label="体积" width="100px" align="center">
                  <template slot-scope="scope">
                    {{
                      scope.row.asnVolume
                    }}
                  </template>
                </el-table-column>
                <el-table-column  label="操作" width="100px" align="center">
                  <template slot-scope="scope">
                    <el-button type="danger" size="mini" @click="delxiangziHandleClick(scope.$index, boxTableData)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table> -->
              <virtul-list
                :table-data="boxTableData"
                @delData="delxiangziHandleClick"
                @computedRow="computedRow"
              />
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-card>
    </div>
    <div class="c-box mt20">
      <el-card>
        <span
          style="
            color: #3f3c40;
            font-size: 12px;
            margin: 0;
            margin-bottom: 20px;
            margin-right: 10px;
            display: inline-block;
          "
        >选择发货商品</span>
        <el-button
          type="primary"
          class="bhe32"
          @click="selectProductData"
        >选择商品</el-button>
        <div
          style="
            border: 1px solid #eee;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 20px;
          "
        >
          <el-tabs v-model="countBoxType" type="card">
            <el-tab-pane label="商品" name="1">
              <el-table
                class="freightRate-table center"
                :data="productTableData"
                style="width: 100%; max-height: 800px"
              >
                <el-table-column width="50" align="center">
                  <template slot="header">
                    <el-tooltip content="计算装箱单" placement="top">
                      <span style="font-size: 12px; cursor: pointer; color: #fff" @click="shopToCase(1)">重新统计装箱单</span>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column prop="sku" label="SKU" align="center" />
                <el-table-column prop="nameCn" label="商品名称(中文)" align="center" />
                <el-table-column label="数量" align="center">
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.quantity"
                      placeholder="请输入数量"
                      class="se36"
                    />
                  </template>
                </el-table-column>
                <el-table-column prop="declaredValue" label="每个申报价值/美元" align="center" />
                <el-table-column prop="hsCode" label="海关编码" align="center" />
                <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <p style="text-align: center">
                      <span
                        style="cursor: pointer"
                        @click="deleteTab3row(scope.row)"
                      ><i
                        class="el-icon-delete"
                        style="color: red; font-size: 20px"
                      /></span>
                    </p>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="装箱单" name="2">
              <el-table
                class="freightRate-table center packing_list"
                :data="formData.orderBoxList"
                style="width: 100%; max-height: 800px"
                default-expand-all
              >
                <el-table-column type="expand" width="50">
                  <template slot="header">
                    <el-tooltip content="计算商品" placement="top">
                      <span style="font-size: 12px; cursor: pointer; color： #fff" @click="shopToCase(2)">重新统计商品</span>
                    </el-tooltip>
                  </template>
                  <template slot-scope="parScope">
                    <el-table
                      class="freightRate-table center"
                      :data="parScope.row.orderCaseShopList"
                      style="width: 100%"
                      :show-header="false"
                    >
                      <el-table-column width="50" />
                      <el-table-column prop="cartonNo" label="箱号" align="center" />
                      <el-table-column prop="fabNo" label="FAB箱号" align="center" />
                      <el-table-column prop="sku" label="SKU" align="center" />
                      <el-table-column prop="nameCn" label="中文名" align="center" />
                      <el-table-column prop="nameEn" label="英文品名" align="center" />
                      <!-- <el-table-column prop="declaredValue" label="申报单价" align="center"></el-table-column> -->
                      <el-table-column prop="quantity" label="数量" align="center" width="100">
                        <template slot-scope="scope">
                          <el-input
                            v-model="scope.row.quantity"
                            placeholder="请输入数量"
                            class="se36"
                          />
                        </template>
                      </el-table-column>
                      <!-- <el-table-column prop="materialQuality" label="材质" align="center"></el-table-column>
                      <el-table-column prop="purpose" label="用途" align="center"></el-table-column>
                      <el-table-column prop="brand" label="品牌" align="center"></el-table-column>
                      <el-table-column prop="weight" label="产品重量" align="center"></el-table-column> -->
                      <el-table-column prop="hsCode" label="海关编码" align="center" />
                      <!-- <el-table-column prop="remark" label="备注" align="center"></el-table-column> -->
                      <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                          <el-button
                            type="danger"
                            size="mini"
                            class="bhe32"
                            @click="delGoods(scope.$index, parScope.$index)"
                          >删除</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </el-table-column>
                <el-table-column prop="cartonNo" label="箱号" align="center" />
                <el-table-column prop="fabNo" label="FAB箱号" align="center" />
                <el-table-column label="SKU" align="center" />
                <el-table-column prop="nameCn" label="中文名" align="center" />
                <el-table-column label="英文品名" align="center" />
                <!-- <el-table-column label="申报单价" align="center"></el-table-column> -->
                <el-table-column label="数量" align="center" width="100" />
                <!-- <el-table-column label="材质" align="center"></el-table-column>
                <el-table-column label="用途" align="center"></el-table-column>
                <el-table-column label="品牌" align="center"></el-table-column>
                <el-table-column label="产品重量" align="center"></el-table-column> -->
                <el-table-column label="海关编码" align="center" />
                <!-- <el-table-column prop="remark" label="备注" align="center"></el-table-column> -->
                <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <div style="display: flex; flex-warp: no-wrap">
                      <el-button
                        type="success"
                        size="mini"
                        class="bhe32"
                        @click="addGoods(scope.row.orderCaseShopList, scope.$index)"
                      >增加</el-button>
                      <el-button
                        type="danger"
                        size="mini"
                        class="bhe32"
                        @click="delZhuangxiangdan(formData.orderBoxList, scope.$index)"
                      >删除</el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-card>
    </div>
    <div class="c-box mt20">
      <el-card>
        <el-form ref="form" :model="formData" label-width="80px">
          <el-form-item label="提货服务" class="noBottom" required>
            <template>
              <el-radio v-model="formData.isPick" :label="0">自己送仓</el-radio>
              <el-radio v-model="formData.isPick" :label="1">上门提货</el-radio>
            </template>
          </el-form-item>
          <p
            style="
              font-size: 12px;
              color: #bbbbbb;
              margin: 0;
              margin-bottom: 20px;
            "
          >
            提货费用需要另外计算，最终以实际账单为准
          </p>
          <el-button
            v-show="formData.isPick"
            type="primary"
            class="bhe32"
            style="margin-bottom: 20px"
            @click="addvisable = true"
          >选择提货地址</el-button>
        </el-form>
        <div
          v-show="formData.isPick"
          style="
            border: 1px solid #eee;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 20px;
          "
        >
          <el-table
            class="freightRate-table center"
            :data="orderTableData"
            style="width: 100%"
          >
            <el-table-column prop="warehouseNo" label="进仓单号" />
            <el-table-column prop="pickTime" label="提货时间" width="165px">
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.pickTime"
                  size="mini"
                  type="datetime"
                  class="xuanshijian"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间"
                />
              </template>
            </el-table-column>
            <el-table-column prop="weight" label="重量(kg)">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.weight"
                  placeholder="请输入"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  class="se36"
                />
              </template>
            </el-table-column>
            <el-table-column prop="volume" label="体积(m³)">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.volume"
                  placeholder="请输入"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  class="se36"
                />
              </template>
            </el-table-column>
            <el-table-column prop="totalCarton" label="箱数">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.totalCarton"
                  placeholder="请输入"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  class="se36"
                />
              </template>
            </el-table-column>
            <el-table-column prop="contacts" label="联系人" />
            <el-table-column prop="phone" label="联系电话" />
            <el-table-column prop="countryName" label="国家" />
            <el-table-column prop="stateName" label="省/州" />
            <el-table-column prop="cityName" label="城市" />
            <el-table-column prop="address" label="提货地址" />
          </el-table>
        </div>
      </el-card>
    </div>
    <div class="c-box mt20">
      <el-form ref="form" :model="formData" label-width="80px">
        <el-card>
          <el-form-item label="报关服务" class="noBottom" required>
            <template>
              <el-radio-group v-model="formData.needDeclare" @change="selectCustomsDeclaration">
                <el-radio :label="0">非退税报关</el-radio>
                <el-radio :label="1">退税报关</el-radio>
              </el-radio-group>
            </template>
          </el-form-item>
          <p
            style="
              font-size: 12px;
              color: #bbbbbb;
              margin: 0;
              margin-bottom: 20px;
            "
          >
            订单确认后，请在订单列表根据提示上传相关证明附件信息
          </p>
          <el-table
            :data="CustomsDeclarationFile"
            border
          >
            <el-table-column
              label="文件名称"
              prop="fileName"
              align="center"
              width="200px"
            />
            <el-table-column
              label="文件地址"
              align="center"
            >
              <template slot-scope="scope">
                <span v-for="(list, i) in scope.row.templateUrlVOS" :key="i" class="item">
                  <a :href="list.absolutePath" target="_Blank">
                    {{ list.fileName }}
                  </a>
                  <i class="el-tag__close el-icon-close" style="cursor: pointer" @click.stop="fileRemove(scope.row.templateUrlVOS,i)" />
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              width="120px"
            >
              <template slot-scope="scope">
                <el-upload
                  style="display:inline-block"
                  class="upload-demo"
                  :action="uploadFileUrl"
                  :on-success="(response)=>downTemplate(scope.row.templateUrlVOS,response)"
                  :on-error="error"
                  :with-credentials="true"
                  :show-file-list="false"
                >
                  <el-button type="primary" size="mini">上传</el-button>
                </el-upload>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <el-card class="mt20">
          <el-form-item label="清关服务" class="noBottom" label-width="80px" required>
            <template>
              <el-radio-group v-model="formData.needClearance" @change="selectCustomsClearance">
                <el-radio :label="0">委托清关</el-radio>
                <el-radio :label="1">单独清关</el-radio>
              </el-radio-group>
            </template>
          </el-form-item>
          <p
            style="
              font-size: 12px;
              color: #bbbbbb;
              margin: 0;
              margin-bottom: 20px;
            "
          >
            订单确认后，请在订单列表根据提示上传相关证明附件信息
          </p>
          <el-table
            :data="CustomsClearanceFile"
            border
          >
            <el-table-column
              align="center"
              prop="fileName"
              width="200px"
              label="文件名称"
            />
            <el-table-column
              label="文件地址"
              align="center"
            >
              <template slot-scope="scope">
                <span v-for="(list, i) in scope.row.templateUrlVOS" :key="i" class="item">
                  <a :href="list.absolutePath" target="_Blank">
                    {{ list.fileName }}
                  </a>
                  <i class="el-tag__close el-icon-close" style="cursor: pointer" @click.stop="fileRemove(scope.row.templateUrlVOS,i)" />
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              width="120px"
            >
              <template slot-scope="scope">
                <el-upload
                  style="display:inline-block"
                  class="upload-demo"
                  :action="uploadFileUrl"
                  :on-success="(response)=>downTemplate(scope.row.templateUrlVOS,response)"
                  :on-error="error"
                  :with-credentials="true"
                  :show-file-list="false"
                >
                  <el-button type="primary" size="mini">上传</el-button>
                </el-upload>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <el-card class="mt20">
          <el-form-item label="订单备注：" label-width="88px">
            <el-input
              v-model="formData.remark"
              type="textarea"
              placeholder="最多可填写200个字符"
              style="width: 100%"
              :maxlength="200"
            />
          </el-form-item>
          <el-form-item label="外部单号：" label-width="88px">
            <el-input
              v-model="formData.extensionNumber"
              style="width: 100%"
            />
          </el-form-item>
        </el-card>
      </el-form>
      <el-card class="mt20">
        <div class="tangc">
          <el-row style="margin: 0 auto">
            <p
              style="
                font-size: 16px;
                padding-left: 10px;
                border-bottom: 1px solid #ddd;
              "
            >
              费用明细<span
                style="font-size: 12px; color: red"
              >仅供参考，待定费用请以最终账单为准</span>
              <el-button size="mini" type="primary" style="margin-left:50px" @click="CalculateCosts">试算费用</el-button>
            </p>
            <el-col class="box" :span="3">
              <p>费用名称</p>
            </el-col>
            <el-col class="box" :span="3">
              <p>数量</p>
            </el-col>
            <el-col class="box" :span="3">
              <p>单价</p>
            </el-col>
            <el-col class="box" :span="6">
              <el-col class="span" :span="12">
                <p>金额</p>
              </el-col>
              <el-col :span="12">
                <p>币种</p>
              </el-col>
            </el-col>
            <el-col class="box" :span="3">
              <p>计算方式</p>
            </el-col>
            <el-col class="box" :span="3">
              <p>数量来源</p>
            </el-col>
            <el-col class="box" :span="3">
              <p>备注</p>
            </el-col>
            <template v-if="orderCopeReceivableVOList">
              <template v-for="item in orderCopeReceivableVOList.orderCopeReceivableVOList">
                <el-col :key="item.id" class="box" :span="3">
                  <p>{{ item.costName }}</p>
                </el-col>
                <template v-if="item.labourAffirm">
                  <el-col :key="item.id" class="box" :span="3">
                    <p>{{ item.count }}</p>
                  </el-col>
                  <el-col :key="item.id" class="box" :span="3">
                    <p>{{ item.unitPrice }}</p>
                  </el-col>
                  <el-col :key="item.id" class="box" :span="6">
                    <el-col class="span" :span="12">
                      <p>{{ item.amount }}</p>
                    </el-col>
                    <el-col :span="12">
                      <p>{{ item.currencyCode }}</p>
                    </el-col>
                  </el-col>
                </template>
                <template v-else>
                  <el-col :key="item.id" class="box" :span="12">
                    <p>待人工确认</p>
                  </el-col>
                </template>
                <el-col :key="item.id" class="box" :span="3">
                  <p v-show="item.calculateWay === 1">自动</p>
                  <p v-show="item.calculateWay === 2">手动</p>
                </el-col>
                <el-col :key="item.id" class="box" :span="3">
                  <p v-show="item.source === 1">计费重</p>
                  <p v-show="item.source === 2">固定</p>
                  <p v-show="item.source === 3">箱数</p>
                </el-col>
                <el-col :key="item.id" class="box" :span="3">
                  <p>{{ item.fillMaterialDescription }}</p>
                </el-col>
              </template>
            </template>
          </el-row>
        </div>
      </el-card>
      <p style="text-align: center">
        <el-button
          v-if="!$route.query.edit"
          round
          style="min-width: 120px; margin-right: 44px"
          @click="submitOrder(2)"
        >暂存</el-button>
        <el-button
          v-loading="orderLoading"
          round
          type="primary"
          style="min-width: 120px"
          @click="submitOrder(1)"
        >提交</el-button>
      </p>
    </div>

    <SelectProduct
      :visible="product"
      :filter-goods-id="filterGoodIds"
      @sublime="sublimeProduct"
      @close="product = false"
    />
    <SelectAddress
      :visible="addvisable"
      @sublime="submitAddress"
      @close="addvisable = false"
    />
    <!-- <OperatingInfo @close="operatingInfo = false" :visible="operatingInfo" :title='this.formData'/> -->
    <el-dialog :visible.sync="operatingInfo" class="product" style="min-width:620px" custom-class="sizeWidth">
      <p class="title">操作说明</p>
      <p class="tip">感谢您信任并使用佳裕达物流管理系统进行下单！ </p>
      <p class="tip">为保障您顺利下单，避免造成不必要的损失，请耐心看完哦！</p>
      <div class="content">
        <p v-for="(obj,index) in forData.remarksList" :key="index">
          <i />{{ obj }}
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  InfoFare,
  createOrder,
  createOrderNum,
  submitOrder,
  noteOrder,
  lookEditOrderInfo,
  getOrderClearanceFiles,
  getOrderCustomsFiles,
  calcOrderCopeReceivable,
  calcOrderCaseList,
  findClearingWay,
  findOrderCaseByOrderId,
  shopToCase,
  caseToShop,
  delCalcOrderByCustomerId,
  importCreateOrderCaseList,
  importCreateOrderCaseListNew
} from '@/api/freight.js';
import SelectProduct from '@/components/SelectProduct';
import SelectAddress from '@/components/SelectAddress';
// import OperatingInfo from "@/components/OperatingInfo";
import * as XLSX from 'xlsx';
import VirtulList from './components/virtul-list.vue';
export default {
  name: 'GoOrder',
  components: {
    SelectProduct,
    SelectAddress,
    VirtulList
    // OperatingInfo,
  },
  props: {},
  data: function() {
    return {
      goods_weight_show: false,
      goods_weight: {},
      guijin: '',
      kujin: '',
      guijin1: '',
      kujin1: '',
      guibi: '',
      kubi: '',
      zongji: '',
      zongshu: '',
      zongzong: '',
      zongti: '',
      Surcharge: '',
      SurchargeCurrency: '',
      filterGoodIds: [],
      forData: {
        fabWarehouseVOList: []
      }, // 详情
      addData: {
        offerInfoId: +this.$route.query.oId,
        amazonReferenceId: '',
        beginNumber: '',
        extensionNumber: '',
        beginNumberPrefix: 'U'
      }, // 添加箱数
      rulesformData: {
        reserveSize: [{ required: true, message: '请选择定柜尺寸' }],
        storeGoodsWarehouseCode: [
          { required: true, message: '请选择集货仓库' }
        ],
        destinationWarehouseCode: [
          { required: true, message: '请选择目的地仓库' }
        ]
      },
      resAddData: {
        cartons: [{ required: true, message: '请输入箱数', trigger: 'blur' }],
        weight: [{ required: true, message: '请输入重量', trigger: 'blur' }],
        length: [{ required: true, message: '请输入长', trigger: 'blur' }],
        width: [{ required: true, message: '请输入宽', trigger: 'blur' }],
        height: [{ required: true, message: '请输入高', trigger: 'blur' }],
        extensionNumber: [{ required: true, message: '请输入', trigger: 'blur' }],
        beginNumber: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      operatingInfo: false, // 查看信息
      boxTableData: [], // 箱数
      product: false, // 选择商品
      productTableData: [], // 展示的table
      addvisable: false, // 选择地址
      orderTableData: [], // 提货地址table
      form: {}, // 详情
      doorRadio: 1,
      addition: {}, // 批量添加箱数
      moneyTableData: [], // 费用明细
      formData: {
        isPick: 0,
        needDeclare: 0,
        needClearance: 0,
        remark: '',
        offerInfoId: +this.$route.query.oId
      }, // 提交数据
      tcshow: true,
      CustomsDeclarationFile: [],
      CustomsClearanceFile: [],
      orderCopeReceivableVOList: null,
      ClearingWay: [],
      computedCostID: '',
      goodsOrderCase: [],
      activeName: 'first',
      uploadFileUrl: process.env.VUE_APP_FILEURL,
      caseIndex: false,
      countBoxType: '1',
      outputs: [],
      virtulLoading: false,
      orderLoading: false
    };
  },
  computed: {
    startTime() {
      if (this.forData.sailTime) {
        const time = this.forData.sailTime.split(' ');
        return time[0];
      } else {
        return '';
      }
    },
    NowClearingWay() {
      let clearingWay;
      this.ClearingWay.forEach(item => {
        if (item.id === this.forData.showClearingWay) {
          clearingWay = item.name;
        }
      });
      return clearingWay;
    }
  },
  watch: {

  },
  created() {
    this.init();
    this.selectCustomsDeclaration(0);
    this.selectCustomsClearance(0);
    this.findClearingWay();
    this.delCalcOrderByCustomerId();
  },
  mounted() {},
  methods: {
    init() {
      // 初始化
      if (this.$route.query.id) {
        if (this.$route.query.typ) {
          this.getinfodata();
          this.findOrderCaseByOrderId();
        } else {
          this.getOreder();
        }
        if (this.$store.state.EGoOrder && !this.$route.query.oId) {
          this.setStore();
        }
      } else {
        this.$router.replace({ path: '/freightRate' });
      }
    },
    findClearingWay() {
      findClearingWay()
        .then(res => {
          if (+res.data.code === 0) {
            this.ClearingWay = res.data.data;
          }
        });
    },
    reTime(time) {
      // 时间格式重置
      if (time) {
        const str = time.split(' ');
        return str[0];
      } else {
        return '';
      }
    },
    setStore() {
      const obj = this.$store.state.EGoOrder;
      this.formData = {
        ...this.formData,
        ...obj
      };
      this.getSearchArrivelWH();
      this.goods_weight.totalAsnVolume = this.formData.caseVO.totalAsnVolume;
      this.goods_weight.totalAsnWeight = this.formData.caseVO.totalAsnWeight;
      this.goods_weight.totalCase = this.formData.caseVO.totalCase;
      this.goods_weight.totalChargeWeight = this.formData.caseVO.totalChargeWeight;
      this.goods_weight.totalVolumeWeight = this.formData.caseVO.totalVolumeWeight;
      this.goods_weight_show = true;
      if (obj.orderCaseVOList.length) {
        this.boxTableData = obj.orderCaseVOList;
      }
      if (obj.orderShopVOList.length) {
        this.productTableData = obj.orderShopVOList;
      }
      if (obj.caseVO.orderCaseVOList.length) {
        this.$set(this.formData, 'orderBoxList', obj.caseVO.orderCaseVOList);
      }
    },
    getinfodata() {
      let reqForm = {};
      if (this.$route.query.id &&
          this.$route.query.oId &&
          this.$route.query.typ &&
          this.$route.query.isReplaceOfferInfo &&
          this.$route.query.replaceOfferInfoId
      ) {
        reqForm = {
          id: this.$route.query.id,
          isReplaceOfferInfo: this.$route.query.isReplaceOfferInfo,
          replaceOfferInfoId: this.$route.query.replaceOfferInfoId
        };
      } else {
        reqForm = { id: this.$route.query.id };
      }
      lookEditOrderInfo(reqForm).then((result) => {
        this.formData = result.data.data;
        this.forData = result.data.data;
        this.boxTableData = this.formData.orderCaseVOList;
        this.productTableData = this.formData.orderShopVOList;
        this.orderTableData = this.formData.orderPickVOList;
        this.orderCopeReceivableVOList = this.formData.orderCostDetailVO;
        this.$set(this.orderCopeReceivableVOList, 'orderCopeReceivableVOList', this.orderCopeReceivableVOList.orderCopeReceivableVOS);
        if (this.forData.specialVersion) {
          this.$alert(`<p style="max-height: 400px; width: 390px;word-wrap: anywhere;">${this.forData.specialVersion}</p>`, '注意', {
            confirmButtonText: '已知晓',
            dangerouslyUseHTMLString: true,
            customClass: 'custom_message_box'
          });
        }
        for (const i in this.forData.oceanFeeList) {
          if (this.forData.reserveSize == this.forData.oceanFeeList[i].specificationCode) {
            this.xinkai('reserveSize', this.forData.oceanFeeList[i]);
          }
        }
        for (const i in this.forData.shippingAreaVOList) {
          if (this.forData.storeGoodsWarehouseCode == this.forData.shippingAreaVOList[i].warehouseCode) {
            this.xinkai('storeGoodsWarehouseCode', this.forData.shippingAreaVOList[i]);
          }
        }
        this.getSearchArrivelWH();
      });
    },
    getSearchArrivelWH() {
      // 带出外面搜索的目的仓库地址
      if (!this.formData.destinationWarehouseCode) {
        this.formData.destinationWarehouseCode = this.$route.query.arrivelWH;
        this.showWarehouseAddress(this.formData.destinationWarehouseCode);
      }
    },
    batchAdd() {
      // 批量添加箱数
      this.$refs['addData'].validate((valid) => {
        if (valid) {
          if (this.addData.extensionNumber == '') {
            this.$message({
              message: '扩展单号不能为空',
              type: 'warning'
            });
            return;
          }
          if (this.addData.beginNumber == '') {
            this.$message({
              message: '起始单号不能为空',
              type: 'warning'
            });
            return;
          }
          this.createOrder();
          this.$set(this.addData, 'extensionNumber', '');
          this.$set(this.addData, 'beginNumber', '');
          this.$set(this.addData, 'amazonReferenceId', '');
        }
      });
    },
    createOrder() {
      // 请求箱号
      const obj = {
        ...this.addData,
        orderCaseVOList: this.boxTableData
      };
      createOrder(obj).then((result) => {
        if (result.data.code == 0) {
          this.boxTableData = result.data.data.orderCaseVOList;
          this.zongshu = result.data.data.totalCase;
          this.zongzong = result.data.data.totalAsnWeight;
          this.zongti = result.data.data.totalAsnVolume;
          this.$set(this.addData, 'cartons', '');
          this.$set(this.addData, 'height', '');
          this.$set(this.addData, 'length', '');
          this.$set(this.addData, 'weight', '');
          this.$set(this.addData, 'width', '');
          this.goods_weight = result.data.data;
          this.goods_weight_show = true;
          this.$set(this.formData, 'orderBoxList', result.data.data.orderBoxList);
        }
      });
    },
    xinkai(name, obj) { // 编辑回显时赋值金额
      if (name == 'reserveSize') {
        this.guijin = obj.unitPriceFormat;
        this.guijin1 = obj.unitPrice;
        this.guibi = obj.currencyCode;
      } else {
        this.kujin = obj.amountFormat;
        this.kujin1 = obj.amount;
        this.kubi = obj.currencyCode;
      }
    },
    reserveSize(code, name, obj) {
      // 设置尺寸,集货
      if (code == this.formData[name]) {
        this.formData[name] = 0;
        this.formData = { ...this.formData };
      } else {
        this.formData[name] = code;
        this.formData = { ...this.formData };
      }
      if (name == 'reserveSize') {
        this.guijin = obj.unitPriceFormat;
        this.guijin1 = obj.unitPrice;
        this.guibi = obj.currencyCode;
      } else {
        this.kujin = obj.amountFormat;
        this.kujin1 = obj.amount;
        this.kubi = obj.currencyCode;
      }
      this.zongji = this.guijin1 + this.kujin1;

      this.$refs['formSelect'].clearValidate();
    },

    sublimeProduct(sel) {
      // 确定选择
      let arr, tab;
      if (this.caseIndex || this.caseIndex === 0) {
        tab = this.formData.orderBoxList[this.caseIndex].orderCaseShopList;
        arr = this.formData.orderBoxList[this.caseIndex].orderCaseShopList;
        for (const i in sel) {
          sel[i].goodId = sel[i].id;
          sel[i].cartonNo = this.formData.orderBoxList[this.caseIndex].cartonNo;
          sel[i].fabNo = this.formData.orderBoxList[this.caseIndex].fabNo;
          let isclone = false;
          for (const k in tab) {
            if (sel[i].id == tab[k].id) {
              isclone = true;
            }
          }
          if (!isclone) {
            sel[i].id = null;
            arr.push(sel[i]);
          }
        }
        this.$set(this.formData.orderBoxList[this.caseIndex], 'orderCaseShopList', arr);
      } else {
        tab = this.productTableData;
        arr = this.productTableData;
        for (const i in sel) {
          sel[i].goodId = sel[i].id;

          let isclone = false;
          for (const k in tab) {
            if (sel[i].id == tab[k].id) {
              isclone = true;
            }
          }
          if (!isclone) {
            arr.push(sel[i]);
          }
        }
        this.productTableData = arr;
      }
      this.caseIndex = false;
    },
    deleteTab3row(row) {
      // 删除选中的商品
      const tab = this.productTableData;
      for (const i in tab) {
        if (row.id == tab[i].id) {
          tab.splice(i, 1);
        }
      }
    },
    submitAddress(row) {
      // 选择地址
      this.createOrderNum(row);
    },
    createOrderNum(obj) {
      // 请求单号
      createOrderNum(obj).then((result) => {
        if (result.status == 200) {
          this.orderTableData = result.data.data;
          if (this.orderTableData.length > 0) {
            this.orderTableData[0].weight = this.zongzong;
            this.orderTableData[0].totalCarton = this.zongshu;
            this.orderTableData[0].volume = this.zongti;
          }
        }
      });
    },
    setforData() {
      return {};
    },
    calcOrderCopeReceivable() {
      const obj = {
        ...this.formData,
        totalCartons: this.addData.cartons,
        orderCaseVOList: this.boxTableData,
        orderShopVOList: this.productTableData,
        orderCustomsFileVOList: this.CustomsDeclarationFile,
        orderClearanceFileVOList: this.CustomsClearanceFile
      };
      if (this.computedCostID) {
        obj.id = this.computedCostID;
      }
      if (this.formData.isPick) {
        obj.orderPickVOList = this.orderTableData;
      }
      calcOrderCopeReceivable(obj).then((result) => {
        if (result.data.code == 0) {
          this.orderCopeReceivableVOList = result.data.data;
          this.computedCostID = result.data.data.id;
          this.$message({
            message: '成功',
            duration: 1500,
            type: 'success'
          });
        } else {
          this.$message({
            message: result.data.msg,
            type: 'warning'
          });
        }
      });
    },
    submitOrder(type) {
      // 提交/暂存
      this.$refs['formSelect'].validate((valid) => {
        if (valid) {
          if (this.boxTableData.length < 1) {
            this.$message({
              message: '请填写FBA箱号信息！',
              type: 'warning',
              center: true
            });
            return false;
          }
          if (this.productTableData.length < 1) {
            this.$message({
              message: '请选择商品信息！',
              type: 'warning',
              center: true
            });
            return false;
          }

          const obj = {
            ...this.formData,
            totalCartons: this.addData.cartons,
            orderCaseVOList: this.boxTableData,
            orderShopVOList: this.productTableData,
            orderCustomsFileVOList: this.CustomsDeclarationFile,
            orderClearanceFileVOList: this.CustomsClearanceFile,
            countBoxType: +this.countBoxType
          };
          if (this.orderCopeReceivableVOList && this.orderCopeReceivableVOList.id) {
            obj.id = this.orderCopeReceivableVOList.id;
            obj.orderNo = this.orderCopeReceivableVOList.orderNo;
          }
          if (this.formData.isPick) {
            obj.orderPickVOList = this.orderTableData;
          }
          if (type == 1) {
            if (this.orderLoading) return;
            this.orderLoading = true;
            submitOrder(obj).then((result) => {
              if (result.data.code == 0) {
                this.$message({
                  message: '成功',
                  duration: 1500,
                  type: 'success'
                });
                this.orderLoading = false;
                this.$router.push({
                  name: 'orderlist'
                });
              } else {
                this.$message({
                  message: result.data.msg,
                  type: 'warning'
                });
                this.orderLoading = false;
              }
            });
          } else {
            noteOrder(obj).then((result) => {
              if (result.data.code == 0) {
                this.$message({
                  message: '成功',
                  duration: 1500,
                  type: 'success'
                });
                this.$router.push({
                  name: 'orderlist'
                });
              } else {
                this.$message({
                  message: result.data.msg,
                  type: 'warning'
                });
              }
            });
          }
        } else {
          document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
      });
    },
    getOreder() {
      InfoFare({ id: this.$route.query.id }).then((result) => {
        if (result.data.code == 0) {
          this.forData = result.data.data;
          if (this.forData.specialVersion) {
            this.$alert(`<p style="max-height: 400px; width: 390px;word-wrap: anywhere;">${this.forData.specialVersion}</p>`, '注意', {
              confirmButtonText: '已知晓',
              dangerouslyUseHTMLString: true
            });
          }
          this.getSearchArrivelWH();
        }
      });
    },
    goto(route) {
      this.$router.push({ path: route });
    },
    selectCustomsDeclaration(val) {
      const offerInfoId = this.$route.query.oId ? +this.$route.query.oId : this.formData.offerInfoId;
      const data = { needDeclare: val, offerInfoId };
      getOrderCustomsFiles(data)
        .then(res => {
          if (+res.data.code === 0) {
            this.CustomsDeclarationFile = res.data.data;
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        });
    },
    selectCustomsClearance(val) {
      const offerInfoId = this.$route.query.oId ? +this.$route.query.oId : this.formData.offerInfoId;
      const data = { needClearance: val, offerInfoId };
      getOrderClearanceFiles(data)
        .then(res => {
          if (+res.data.code === 0) {
            this.CustomsClearanceFile = res.data.data;
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        });
    },
    downTemplate(obj, response) {
      if (response.code == 200) {
        obj.push(response.data);
        this.$message({
          message: '上传成功',
          duration: 1500,
          type: 'success'
        });
      } else {
        this.$message({
          message: response.msg,
          duration: 1500,
          type: 'error'
        });
      }
    },
    // 上传失败
    error() {
      this.$message({
        message: '上传失败',
        duration: 1500,
        type: 'error'
      });
    },
    fileRemove(obj, i) {
      obj.splice(i, 1);
    },
    changeComputeData() {
      calcOrderCaseList({ offerInfoId: +this.$route.query.oId, orderCaseVOList: this.boxTableData })
        .then(res => {
          if (+res.data.code === 0) {
            this.goods_weight = res.data.data;
            this.boxTableData = res.data.data.orderCaseVOList;
          }
        });
    },
    specialMessage() {
      if (this.forData.specialVersion) {
        this.$alert(`<p style="max-height: 400px; width: 390px;word-wrap: anywhere;">${this.forData.specialVersion}</p>`, '注意', {
          confirmButtonText: '已知晓',
          dangerouslyUseHTMLString: true
        });
      } else {
        this.$message({
          type: 'info',
          message: '该报价无特别说明'
        });
      }
    },
    CalculateCosts() {
      // this.delCalcOrderByCustomerId();
      this.calcOrderCopeReceivable();
    },
    findOrderCaseByOrderId() {
      findOrderCaseByOrderId({ id: this.$route.query.id })
        .then(res => {
          if (+res.data.code === 0) {
            this.goodsOrderCase = res.data.data;
          } else {
            this.message({
              type: 'error',
              message: res.data.msg
            });
          }
        });
    },
    shopToCase(type, method) {
      // this.delCalcOrderByCustomerId();
      this.$refs['formSelect'].validate((valid) => {
        if (valid || method === 'upload') {
          if (this.boxTableData.length < 1) {
            this.$message({
              message: '请填写FBA箱号信息！',
              type: 'warning',
              center: true
            });
            return false;
          }
          // if (this.productTableData.length < 1) {
          //   this.$message({
          //     message: "请选择商品信息！",
          //     type: "warning",
          //     center: true,
          //   });
          //   return false;
          // }

          const obj = {
            ...this.formData,
            totalCartons: this.addData.cartons,
            orderCaseVOList: this.boxTableData,
            orderShopVOList: this.productTableData,
            countBoxType: +type,
            orderCustomsFileVOList: this.CustomsDeclarationFile,
            orderClearanceFileVOList: this.CustomsClearanceFile
          };
          if (this.orderCopeReceivableVOList && this.orderCopeReceivableVOList.id) {
            obj.id = this.orderCopeReceivableVOList.id;
            obj.orderNo = this.orderCopeReceivableVOList.orderNo;
          }
          if (this.formData.isPick) {
            obj.orderPickVOList = this.orderTableData;
          }
          if (type === 1) {
            shopToCase(obj)
              .then(res => {
                if (+res.data.code === 0) {
                  this.formData = res.data.data;
                  this.boxTableData = res.data.data.caseVO.orderCaseVOList;
                  this.productTableData = res.data.data.orderShopVOList;
                  this.$message({
                    type: 'success',
                    message: res.data.msg
                  });
                } else {
                  this.$message({
                    type: 'error',
                    message: res.data.msg
                  });
                }
              });
          }
          if (type === 2) {
            caseToShop(obj)
              .then(res => {
                if (+res.data.code === 0) {
                  this.formData = res.data.data;
                  this.boxTableData = res.data.data.caseVO.orderCaseVOList;
                  this.productTableData = res.data.data.orderShopVOList;
                  this.$message({
                    type: 'success',
                    message: res.data.msg
                  });
                } else {
                  this.$message({
                    type: 'error',
                    message: res.data.msg
                  });
                }
              });
          }
        }
      });
    },
    delCalcOrderByCustomerId() {
      delCalcOrderByCustomerId({})
        .then(res => {
          if (+res.data.code === 0) {
            console.log(res);
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        }).catch(err => {
          this.$message({
            type: 'error',
            message: err.data.msg
          });
        });
    },
    showWarehouseAddress(val) {
      this.forData.fabWarehouseVOList.forEach(item => {
        if (item.warehouseCode === val) {
          this.formData.destinationWarehouseAddress = item.addressFirst;
          this.$set(this.formData, 'destinationWarehouseAddress', item.zipCode ? item.addressFirst + '  |  ' + item.zipCode : item.addressFirst);
          this.$set(this.formData, 'destinationWarehouseName', item.warehouseName);
        }
      });
    },
    selectProductData() {
      this.caseIndex = false;
      this.filterGoodIds = [];
      this.productTableData.forEach(item => {
        if (this.filterGoodIds.indexOf(item.goodId) === -1) {
          this.filterGoodIds.push(item.goodId);
        }
      });
      this.product = true;
    },
    addGoods(shopArr, index) {
      this.caseIndex = index;
      this.filterGoodIds = [];
      shopArr.forEach(item => {
        if (this.filterGoodIds.indexOf(item.goodId) === -1) {
          this.filterGoodIds.push(item.goodId);
        }
      });
      this.product = true;
    },
    delGoods(index, parIndex) {
      this.formData.orderBoxList[parIndex].orderCaseShopList.splice(index, 1);
    },
    delxiangziHandleClick(val) {
      this.boxTableData.splice(val, 1);
    },
    delZhuangxiangdan(tableData, index) {
      tableData.splice(index, 1);
    },
    downloadTemplate() {
      this.$refs.downloadEL.click();
    },
    importFrontEndExcel() {
      this.$refs.upload.click();
    },
    readExcel(e) {
      const that = this;
      const files = e.target.files;
      if (files.length < 1) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$toast('上传文件格式不正确，请上传xls或者xlsx格式');
        return false;
      }

      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: 'binary'
          }); // 读取数据
          const wsname = workbook.SheetNames[0]; // 取第一张表
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); // 生成json表格内容
          // const ws = XLSX.utils.sheet_to_slk(workbook.Sheets[wsname]) // 输出表格对应位置是什么值
          // const ws2 = XLSX.utils.sheet_to_html(workbook.Sheets[wsname]) // 生成HTML输出
          // const ws3 = XLSX.utils.sheet_to_csv(workbook.Sheets[wsname]) // 生成分隔符分隔值输出
          // const ws4 = XLSX.utils.sheet_to_formulae(workbook.Sheets[wsname]) // 生成公式列表（具有值回退）
          // const ws5 = XLSX.utils.sheet_to_txt(workbook.Sheets[wsname]) // 生成UTF16格式的文本

          that.outputs = []; // 清空接收数据

          const outputList = [];
          const offerInfoId = +this.$route.query.oId;
          for (let i = 0; i < ws.length; i++) {
            const sheetData = ws[i]; // 对数据自行操作
            outputList.push(sheetData);
          }
          outputList.forEach(item => {
            item.offerInfoId = offerInfoId;
            item.orderCaseVOList = [];
            item.amazonReferenceId = item['Amazon Reference ID'] || item['Amazon Reference ID*'];
            item.extensionNumber = (item['货箱编号'] || item['货箱编号*'])?.slice(0, 12);
            // item.extensionNumber = item['客户订单号'] || item['客户订单号*']
            // item.beginNumberPrefix = item['起始单号（前缀）']
            // item.beginNumber = item['起始单号']
            item.cartons = 1;
            item.weight = item['货箱重量(KG)'] || item['货箱重量(KG)*'];
            item.length = item['货箱长度(CM)'] || item['货箱长度(CM)*'];
            item.width = item['货箱宽度(CM)'] || item['货箱宽度(CM)*'];
            item.height = item['货箱高度(CM)'] || item['货箱高度(CM)*'];
            item.fabNo = item['货箱编号'] || item['货箱编号*'];

            // delete item['亚马逊引用ID']
            // delete item['扩展单号']
            // delete item['起始单号（前缀）']
            // delete item['起始单号']
            // delete item['总箱数']
            // delete item['每箱重量(KG)']
            // delete item['长']
            // delete item['宽']
            // delete item['高']
          });
          that.outputs = outputList;
          that.$refs.upload.value = '';
          that.importCreateOrderCaseList();
        } catch (e) {
          console.log(e);
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    readOwnExcel(e) {
      const that = this;
      const files = e.target.files;
      if (files.length < 1) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$toast('上传文件格式不正确，请上传xls或者xlsx格式');
        return false;
      }

      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: 'binary'
          }); // 读取数据
          const wsname = workbook.SheetNames[0]; // 取第一张表
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); // 生成json表格内容
          // const ws = XLSX.utils.sheet_to_slk(workbook.Sheets[wsname]) // 输出表格对应位置是什么值
          // const ws2 = XLSX.utils.sheet_to_html(workbook.Sheets[wsname]) // 生成HTML输出
          // const ws3 = XLSX.utils.sheet_to_csv(workbook.Sheets[wsname]) // 生成分隔符分隔值输出
          // const ws4 = XLSX.utils.sheet_to_formulae(workbook.Sheets[wsname]) // 生成公式列表（具有值回退）
          // const ws5 = XLSX.utils.sheet_to_txt(workbook.Sheets[wsname]) // 生成UTF16格式的文本

          that.outputs = []; // 清空接收数据

          const outputList = [];
          const offerInfoId = +this.$route.query.oId;
          for (let i = 0; i < ws.length; i++) {
            const sheetData = ws[i]; // 对数据自行操作
            outputList.push(sheetData);
          }
          outputList.forEach(item => {
            item.offerInfoId = offerInfoId;
            item.orderCaseVOList = [];
            item.amazonReferenceId = item['亚马逊引用ID'];
            item.extensionNumber = item['扩展单号'];
            item.beginNumberPrefix = item['起始单号（前缀）'];
            item.beginNumber = item['起始单号'];
            item.cartons = item['总箱数'];
            item.weight = item['每箱重量(KG)'];
            item.length = item['长(CM)'];
            item.width = item['宽(CM)'];
            item.height = item['高(CM)'];

            // delete item['亚马逊引用ID']
            // delete item['扩展单号']
            // delete item['起始单号（前缀）']
            // delete item['起始单号']
            // delete item['总箱数']
            // delete item['每箱重量(KG)']
            // delete item['长']
            // delete item['宽']
            // delete item['高']
          });
          that.outputs = outputList;
          that.$refs.upload2.value = '';
          that.importCreateOwnOrderCaseList();
        } catch (e) {
          console.log(e);
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    computedRow(res) {
      this.goods_weight = res.data.data;
      this.boxTableData = res.data.data.orderCaseVOList;
    },
    importCreateOwnOrderCaseList() {
      this.virtulLoading = true;
      importCreateOrderCaseListNew(this.outputs)
        .then(result => {
          if (result.data.code == 0) {
            this.boxTableData = result.data.data.orderCaseVOList;
            this.zongshu = result.data.data.totalCase;
            this.zongzong = result.data.data.totalAsnWeight;
            this.zongti = result.data.data.totalAsnVolume;
            this.$set(this.addData, 'cartons', '');
            this.$set(this.addData, 'height', '');
            this.$set(this.addData, 'length', '');
            this.$set(this.addData, 'weight', '');
            this.$set(this.addData, 'width', '');
            this.goods_weight = result.data.data;
            this.goods_weight_show = true;
            this.$set(this.formData, 'orderBoxList', result.data.data.orderBoxList);
          } else {
            this.$message.error(result.data.msg);
          }
          this.virtulLoading = false;
        }).catch(() => {
          this.virtulLoading = false;
        });
    },
    importCreateOrderCaseList() {
      this.virtulLoading = true;
      importCreateOrderCaseList(this.outputs)
        .then(result => {
          if (result.data.code == 0) {
            this.boxTableData = result.data.data.orderCaseVOList;
            this.zongshu = result.data.data.totalCase;
            this.zongzong = result.data.data.totalAsnWeight;
            this.zongti = result.data.data.totalAsnVolume;
            this.$set(this.addData, 'cartons', '');
            this.$set(this.addData, 'height', '');
            this.$set(this.addData, 'length', '');
            this.$set(this.addData, 'weight', '');
            this.$set(this.addData, 'width', '');
            this.goods_weight = result.data.data;
            this.goods_weight_show = true;
            this.$set(this.formData, 'orderBoxList', result.data.data.orderBoxList);
          }
          this.virtulLoading = false;
        }).catch(() => {
          this.virtulLoading = false;
        });
    }
  }
};
</script>

<style scoped>
.tangc {
  /* position: fixed; */
  background: #fff;
  top: 600px;
  right: 100px;
  width: 100%;
  font-size: 14px;
  border: 1px solid #ddd;
  z-index: 555;
}
.tangc p {
  margin: 0;
  height: 40px;
  line-height: 36px;
}
.tangc .box {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  text-align: center;
}
.tangc .span {
  border-right: 1px solid #ddd;
}
.mt20 {
  margin-top: 20px;
}

.head {
  margin-top: 35px;
  font-size: 14px;
}
.head p {
  margin: 0;
}
.head-left {
  margin-top: 15px;
}
.head-left p {
  min-height: 64px;
}
.head-right .title {
  font-weight: bold;
}
.hr {
  width: 1px;
  height: 200px;
  margin: 0 auto;
  display: inline-block;
  background: #eeeeee;
  margin-top: 20px;
}
.head-right p {
  line-height: 35px;
}
.head-right i {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 13px;
  border-radius: 3px;
  background: #507eff;
}
/* .head-right{
  max-height: 240px;
  overflow: auto;
} */
.click {
    font-size: 12px;
    text-align: left;
    color: #507eff;
    margin: 0;
    margin-bottom: 12px;
    text-indent: 8%;
}
.click span {
  cursor: pointer;
}
.click i {
  width: 14px;
  height: 14px;
  border: 1px solid #507eff;
  border-radius: 8px;
  line-height: 14px;
  text-align: center;
  font-size: 12px;
  margin-right: 10px;
}
.border {
  height: 4px;
  background: #f8f8f8;
}
.form {
  font-size: 14px;
}
.remind {
  font-size: 12px;
  color: #ff9b9b;
  margin: 10px 0;
  line-height: 12px;
}

.money {
  font-size: 24px;
  font-weight: bold;
  margin-right: 10px;
}
.money {
  color: #fe3e1f;
  font-size: 16px;
  font-weight: normal;
  margin: 40px 0;
}
.money span {
  font-size: 16px;
}
.money i {
  font-size: 24px;
  font-style: inherit;
}
.money .tit {
  color: #333;
  font-size: 14px;
}
.ckg {
  margin-right: 10px;
  width: calc(100% - 10px);
}
.select {
  background: #fc6c3f;
  color: #fff;
  border-color: #fc6c3f !important;
}
.content p{line-height:35px;margin:0;}
.content i{display:inline-block;width:6px;height:6px;margin-right:13px;border-radius:3px;background:#507EFF;}
.title{font-size: 18px;
    margin: 0;
    text-align: center;
    font-weight: bold;
    color: #333;
    margin-top: -20px;
    margin-bottom: 32px;}
.tip{font-size: 14px;
    line-height: 24px;
    text-align: center;
    margin: 0;}
.content{margin:10px 60px 0;}
.bianda{
  font-size: 16px;
  font-weight: 700;
}
.special-message {
  position: absolute;
  right: 0;
  top: 0;
  width: 120px;
  margin-right: 0px;
}
.special-message2 {
  position: absolute;
  right: 0;
  top: 50px;
}
.packing_list /deep/ .el-table__expanded-cell[class*=cell] {
  padding: 20px 0
}
::v-deep .el-table__body-wrapper {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  max-height: 700px;
}
</style>
